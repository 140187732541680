// СЧЕТЧИК
$(function () {
  (function quantityProducts() {
    let $quantityNum;

    $('body').on('click', '.js-number-minus', function () {
      quantityMinus(this);
    });
    $('body').on('click', '.js-number-plus', function () {
      quantityPlus(this);
    });

    function quantityMinus($this) {
      $quantityNum = $($this).closest('.js-number').find('.js-number-input');

      if ($quantityNum.val() > 1) {
        $quantityNum.val(+$quantityNum.val() - 1);
      }

      quantityActive($this, $quantityNum);
    }

    function quantityPlus($this) {
      $quantityNum = $($this).closest('.js-number').find('.js-number-input');
      $quantityNum.val(+$quantityNum.val() + 1);
      quantityActive($this, $quantityNum);
    }

    function quantityActive($this, $quantityNum) {
      if (!$quantityNum.hasClass('goods__number-input')) {
        $quantityNum.change();
      }

      // if ($($this).closest('.js-number').hasClass('js_slice_class')) {

      $('.priceChange').each(function () {
        const data_price = $('.goods__price-text').data('price');
        const data_old_price = $('.goods__price-text').data('old-price');
        const data_price_dop = $('.goods__price-text').data('price-dop');

        $(this).data('price', data_price * $quantityNum.val() + data_price_dop);
        if (data_old_price != '') {
          $(this).data(
            'old-price',
            data_old_price * $quantityNum.val() + data_price_dop
          );
        }

        if ($(this).is(':checked')) {
          $('.price-value').html(
            data_price * $quantityNum.val() + data_price_dop
          );
          if (data_old_price != '') {
            $('.old-price-value').html(
              data_old_price * $quantityNum.val() + data_price_dop
            );
          }
        }
      });
      // }
      $($this)
        .closest('.js-number')
        .toggleClass('active-primary', Number($quantityNum.val()) > 1);
    }
  })();
});
