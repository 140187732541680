$('.js-order-urgently-delivery').on('click', function(e) {
    if ($(this).is(':checked')) {
        $('#orderform-delivery_options').val($(this).val())
    }else {
        $('#orderform-delivery_options').val(0)
    }
    const delivery = $('#orderform-delivery').val();
    const region_id = $('#orderform-region_id').val();
    const urgentlyDelivery = $('#orderform-delivery_options').val();
    $.ajax({
        url: '/cart/update-price',
        type: 'GET',
        data: {delivery, region_id, urgentlyDelivery},
        success(res) {
            data = $.parseJSON(res);
            $('.total__price').html(data);
        },
        error(err) {
            console.log(err);
        }
    });
});
