
$('body').on('click','.btn-integrate',function (e) {
    e.preventDefault();
    var active = $(this).data('active');
    var last = $(this).data('last');
    var ids = '';
    var shops = '';
   $( "select[name='categories']").each(function( index ) {
       if($(this).val() != ''){

               ids += $(this).parent().data('id') +  ',' +$(this).val()+';';
       }

    });

    $( "select[name='shops']").each(function( index ) {
        if($(this).val() != ''){
          console.log("!!!!");
            shops += $(this).parent().data('id') +  ',' +$(this).val()+';';
        }

    });
   if(ids != ''){
       ids = ids.substring(0,ids.length - 1)
   }
    if(shops != ''){
        shops = shops.substring(0,shops.length - 1)
    }
    $('#modalclass').modal('hide');
    $('.loader').css("display", "block");
    $.ajax({
        url: '/admin/integrate/add',
        data: {active: active, last: last, ids:ids, shops:shops},
        type: 'GET',
        success: function (res) {
            $('.loader').css("display", "none");
        },
        error: function (err) {
            console.log("Ошибка добавления товара в избранное. Проверь url обработчика в файле js/359.js");
            console.log(err);
            $('.loader').css("display", "none");
        }
    });
});


$('body').on('click', '.js-size-photo', function (e) {
    e.preventDefault();
    var photo_id_field = $(this).data('id');
    var product_id_field = $(this).data('product');
    $('.photo_id_field').val(photo_id_field);
    $('.product_id_field').val(product_id_field);
    $.ajax({
        url: '/admin/product/get-size',
        data: {id: photo_id_field, product_id:product_id_field},
        type: 'GET',
        success: function (res) {
            var result = JSON.parse(res);
            $('#sizephotoflowerform-size').val(result['size'])
            $('#alt-textbox').val(result['alt'])
            $('#modal-size').modal('show');

        },
        error: function (err) {
            console.log("Ошибка добавления товара в избранное. Проверь url обработчика в файле js/359.js");
            console.log(err);
            $('.loader').css("display", "none");
        }
    });


});

$('#size-form').on('beforeSubmit', function () {
    var $yiiform = $(this);
    $.ajax(
        {
            type: $yiiform.attr('method'),
            url: $yiiform.attr('action'),
            data: $yiiform.serializeArray(),
        }
    ).done(function (data) {
        // success actions
        $yiiform.enableSubmitButtons(); // enable the submit buttons
    });

    return false; // prevent default form submission
});


function changeMainImage(el){
    var currentId = $(el).data('id');
    var currentPhoto = $(el).data('text');
    var photoId = $(el).data('photoid');
    var photo = $(el).data('photo');
    $.ajax({
        url: '/admin/product/change-main-photo',
        data: {currentId: currentId, currentPhoto:currentPhoto, photoId:photoId, photo:photo},
        type: 'get',
        success: function (res) {
            if(res==0) alert('Загрузите главную картинку заного!');
            location.reload(true);
        },
        error: function (err) {
            alert("Ошибка!");
        }
    });
}

$('.modalShow').click(function(){
    var id = $(this).data('id');
    $('#sendRequest').text('Подтвердить платёж');
    $.ajax({
        type: "POST",
        url: '/sberbank/default/get-form-deposit-do',
        data: {order_id: id},
        beforeSend: function () {
            $('#sendRequest').addClass('disabled');
        },
        complete: function () {
            $('#sendRequest').removeClass('disabled');
        },
        success: function(responese) {
            if(!responese){
                $('.modal-header').html('<h3>Ошибка</h3>');
                $('.modal-body').html('<h4>Не возможно получить ответ от сервера!</h4>');
            }else{
                $('.modal-header').html('<center><h3 class="text-success">Подтвердить платёж </h3><b>Для списания средств со счёта клиента завершите оплату нажимая кнопку "Подтвердить платёж"</b></center>');
                $('.modal-body').html(responese);
            }
        },
        error: function (err) {
            $('.modal-body').html('<h3 class="text-danger">Отправка запроса временно недоступен!</h3>');
            $('.modal-header').html('<h3>Ошибка</h3>');
        }
    });
});

$('#sendRequest').click(function(){
    var id = $('#invoiceInput').val();
    var formAction = $('#sendRequestForm').attr('action');
    $.ajax({
        type: "POST",
        url: formAction,
        data: {invoice_id: id},
        beforeSend: function () {
            $(this).addClass('disabled');
        },
        complete: function () {
            $(this).removeClass('disabled');
        },
        success: function(responese) {
            var res = JSON.parse(responese);
            if(res['status'] == 'error') {
                $('.modal-body').html('<center><h3>'+res["message"]+'</h3></center>');
            }else{
                $('.modal-body').html('<center><h3>'+res["message"]+'</h3></center>');
                $('#sendRequest').remove();
            }
        },
        error: function (err) {
            $('.modal-body').html('<h3 class="text-danger">Отправка запроса временно недоступен!</h3>');
            $('.modal-header').html('<h3>Ошибка</h3>');
        }
    });
});

$('.modalShowRevers').click(function(){
    var id = $(this).data('id');
    $('#sendRequest').text('Отменить платёж');
    $.ajax({
        type: "POST",
        url: '/sberbank/default/get-form-reverse-do',
        data: {order_id: id},
        beforeSend: function () {
            $('#sendRequest').addClass('disabled');
        },
        complete: function () {
            $('#sendRequest').removeClass('disabled');
        },
        success: function(responese) {
            if(!responese){
                $('.modal-header').html('<h3>Ошибка</h3>');
                $('.modal-body').html('<h4>Не возможно получить ответ от сервера!</h4>');
            }else{
                $('.modal-header').html('<center><h3 class="text-danger">Отменить платёж </h3><b><i>Для отмены оплаты заказа нажмите на кнопку "Отменить платёж"</i></b><br>Операция отмены оплаты может быть совершена только один раз. Если она закончится ошибкой, то повторная операция отмены платежа не пройдёт. Эта функция доступна магазинам по согласованию с банком. Для выполнения операции отмены продавец должен обладать соответствующими правами.</center>');
                $('.modal-body').html(responese);
            }
        },
        error: function (err) {
            $('.modal-body').html('<h3 class="text-danger">Отправка запроса временно недоступен!</h3>');
            $('.modal-header').html('<h3>Ошибка</h3>');
        }
    });
});

function saveAutoOrderAdmin(el){
    var id = $(el).data('id');
    var quantity = $('#quantity'+id).val();
    $.ajax({
        url: '/admin/auto-order/save-auto-order',
        type: 'POST',
        data: {quantity: quantity, id:id},
        success: function (res) {
            var result = JSON.parse(res);
            alert(result['message']);
        },
        error: function () {
            alert('Error!');
        }
    });
}

function deleteAutoOrderAdmin(el){
    var id = $(el).data('id');
    $.ajax({
        url: '/admin/auto-order/delete-auto-order',
        type: 'POST',
        data: { id: id },
        success: function (res) {
            var result = JSON.parse(res);
            $('#raw'+id).remove();
            alert('Успешно удалён!');
        },
        error: function () {
            alert('Error!');
        }
    });
}