// PopUp
$(document).ready(() => {
  const jsxModalClick = $('[data-jsx-modal-target]');
  const jsxModal = $('.jsx-modal');
  const jsxModalClose = $('.jsx-modal__close');

  const jsxBody = $('body');
  let dataJsxModal;
  // Open popup
  jsxModalClick.click(function (event) {
    event.preventDefault();
    $(this).addClass('jsx-active');
    dataJsxModal = $(this).data('jsx-modal-target');
    $(`[data-jsx-modal-id='${dataJsxModal}']`).fadeIn();
    jsxBody.addClass('jsx-hidden');
  });

  // close popup
  jsxBody.click((e) => {
    const jsxTargetBlock = $(e.target);
    let clickModal;
    if (
      jsxTargetBlock.has(`[data-jsx-modal-target="${dataJsxModal}"]`).length ||
      jsxTargetBlock.closest(`[data-jsx-modal-target="${dataJsxModal}"]`).length
    ) {
      clickModal = true;
    }
    if (
      !(
        jsxModalClick.hasClass('jsx-active') &&
        jsxTargetBlock.closest('.jsx-modal__block').length == 0 &&
        !clickModal
      )
    ) {
      return;
    }
    jsxModal.fadeOut();
    jsxBody.removeClass('jsx-hidden');
  });

  jsxModalClose.click(function (event) {
    const jsxModal = $(this).closest('.jsx-modal');
    jsxModal.fadeOut();
    jsxBody.removeClass('jsx-hidden');
  });
});
