(() => {
  const $ = jQuery;

  let max_end_time = $('.js-time-input').data('time_end');
  max_end_time = Math.floor(max_end_time / 60);
  const today_date = new Date();
  if (today_date.getHours() >= max_end_time) {
    today_date.setDate(today_date.getDate() + 1);
    //Tooltip
    const current_day = '.datepicker--cell-day.-current-.-disabled-';
    $('body').on('mouseover', current_day, function (e) {
      const offset = $(this).offset();
      tooltipDatePicker.show(offset);
    });
    $('body').on('mouseout', current_day, (e) => {
      tooltipDatePicker.hide();
    });
  }

  /**
   *  Добавить в корзину
   */
  $('body').on('click', '.addToCart', function (e) {
    e.preventDefault();
    const id = $(this).data('id');
    const qty = $(this).closest('.js-wrap-btn').find('.input-Qty').val();

    const dop = '';
    const dops = $('.js-tags-checkbox li');
    $(dops).each(function (index) {
      dop += `${$(this).data('id-checked')},`;
    });
    if (dop.length > 1) {
      dop = dop.substring(0, dop.length - 1);
    }
    if (!$(this).hasClass('not-size')) {
      var size = $("input[name='prices']:checked").data('name');
    }
    $.ajax({
      url: '/cart/add/',
      data: { id, qty, dop, size },
      type: 'GET',
      success(res) {
        refreshDataInCart();
        refreshHeaderInfo();
      },
      error(err) {},
    });
  });

  /**
   *  Добавить в избранное
   */
  $('body').on('click', '.close_cart', function (e) {
    e.preventDefault();
    const id = $(this).data('id');
    $.ajax({
      url: '/cart/del-item',
      data: { id },
      type: 'GET',
      success(res) {
        refreshDataInCart();
        refreshHeaderInfo();
      },
      error(err) {},
    });
  });

  /**
   *  Добавить в избранное
   */
  $('body').on('click', '.favourites', function (e) {
    const id = $(this).data('id');
    const qty = $('#qtyInp').val();
    $.ajax({
      url: '/favourites/add',
      data: { id, qty },
      type: 'GET',
      success(res) {
        refreshHeaderFavourites();
      },
      error(err) {},
    });
    $(this).addClass('favourites-remove');
    $(this).removeClass('favourites');
  });

  $('body').on('click', '.favourites-remove', function (e) {
    const id = $(this).data('id');
    const qty = $('#qtyInp').val();
    $.ajax({
      url: '/favourites/remove',
      data: { id, qty },
      type: 'GET',
      success(res) {
        refreshHeaderFavourites();
      },
      error(err) {},
    });
    $(this).addClass('favourites');
    $(this).removeClass('favourites-remove');
  });

  /**
   * Обновление кол-ва товаров в хеадере
   */
  function refreshHeaderInfo() {
    $.ajax({
      url: '/cart/get-qty',
      data: {},
      type: 'GET',
      success(res) {
        const data = $.parseJSON(res);
        $('.block-mobile-basket__amount').text(data.count);
        $('.head-bottom__basket-pack').html(data.render);
      },
    });
  }

  /**
   * Обновление кол-ва избранного в хеадере
   */
  function refreshHeaderFavourites() {
    $.ajax({
      url: '/favourites/get-qty',
      data: {},
      type: 'GET',
      success(res) {
        $('.head-top__amount').text(res);
      },
    });
  }

  /**
   * Обновление кол-ва товаров в выползающей корзине
   */
  function refreshDataInCart() {
    $.ajax({
      url: '/cart/get-data-in-cart',
      data: {},
      type: 'GET',
      success(res) {
        $('.basket-order').html(res);
      },
      error(err) {},
    });
  }

  /**
   * Страница обзора товара - изменение кол-ва для добавления в корзину
   */
  $('.input-Qty').bind('change', function (e) {
    $(this)
      .closest('.js-wrap-btn')
      .find('.js-goods__buy-btn')
      .attr('data-qty', this.value);
  });
  setTimeout(() => {
    $('.js_number-minus').click(() => {
      const qty = $('#qtyInp').val();
      $('.goods__buy-btn').attr('data-qty', qty);
    });
  }, 1000);
  setTimeout(() => {
    $('.js_number-plus').click(() => {
      const qty = $('#qtyInp').val();
      $('.goods__buy-btn').attr('data-qty', qty);
    });
  }, 1000);

  /**
   * Корзина - изменение кол-ва товаров
   */

  function updateCount(item) {
    const newQty = item.val();
    const currentProdId = item.data('id');
    const delivery = $('#orderform-delivery').val();
    const urgentlyDelivery = $('#orderform-delivery_options').val();
    const region_id = $('#orderform-region_id').val();
    const priceWrap1 = $(`.cost__product${currentProdId} span`);

    $.ajax({
      url: '/cart/edit-qty',
      data: {
        id: currentProdId,
        qty: newQty,
        delivery,
        region_id,
        urgentlyDelivery,
        tp: $('.total__price_desc .delivery-price span').text(),
      },
      type: 'GET',
      success(res) {
        refreshDataInCart();
        refreshHeaderInfo();
        data = $.parseJSON(res);

        if (data.deliveryPrice == 0) {
          $('.delivery__price').html(
            `<strike>${data.priceDeliveryOld}</strike> -  ${data.priceDelivery}`
          );
        } else {
          $('.delivery__price').html(data.priceDelivery);
        }

        priceWrap1.html(data.productPrice);
        $('.c-discount').remove();
        $('.c-total').html(data.renderTotalPrice);
        $(`.js-number-input${currentProdId}`).val(newQty);
      },
    });
  }

  $('body').on(
    'change',
    '.content-info-cart .js-number-input',
    function (event) {
      updateCount($(this));
    }
  );

  $('body').on(
    'change',
    '.content-info-cart .js-number-input-modal',
    function (event) {
      updateCount($(this));
    }
  );

  $('body').on('click', '.js-number-minus_modal', function () {
    $quantityNum = $(this).closest('.js-number').find('.js-number-input-modal');
    if ($quantityNum.val() > 1) {
      $quantityNum.val(+$quantityNum.val() - 1);
    }
    updateCount($quantityNum);
  });

  $('body').on('click', '.js-number-plus_modal', function () {
    $quantityNum = $(this).closest('.js-number').find('.js-number-input-modal');
    $quantityNum.val(+$quantityNum.val() + 1);
    updateCount($quantityNum);
  });

  /** Изменение региона **/
  $('body').on('change', '.js-region-select', function (event) {
    const region_id = $(this).val();
    const delivery = $('#orderform-delivery').val();
    const urgentlyDelivery = $('#orderform-delivery_options').val();
    $('#orderform-region_id').val(region_id);
    $.ajax({
      url: '/cart/edit-region',
      data: {
        region_id,
        delivery,
        urgentlyDelivery,
        tp: $('.total__price_desc .delivery-price span').text(),
      },
      type: 'GET',
      success(data) {
        data = $.parseJSON(data);
        if (data.priceDeliveryOld === '') {
          $('.delivery__price').html(data.priceDelivery);
        } else {
          $('.delivery__price').html(
            `<strike>${data.priceDeliveryOld}</strike> -  ${data.priceDelivery}`
          );
        }
        $('.total__price_desc').html(data.renderTotalPrice);
        $('.total__price_mob').html(data.renderTotalPrice);
      },
    });
  });

  /**
   * Карточка товара - Изменение цены при изменении размера XL, S...
   */
  $('.priceChange').click(function () {
    const currentSum = $(this).data('price');
    const oldSum = $(this).data('old-price');
    const size = $(this).data('name');
    const el = $(`.size-${size}`);
    el.trigger('click');

    $('.price-value').text(currentSum);
    $('.old-price-value').text(oldSum);
  });

  /**
   * Карточка товара - допы
   */
  $('.dops').click(function () {
    const dop = $(this)
      .children()
      .children('.dopPrice')
      .text()
      .replace(/[^0-9]/g, '');
    const oldPrice = $('.goods__price-text').data('price');

    const newPrice = parseInt(dop) + parseInt(oldPrice);
    $('.goods__price-text').text(`${newPrice} руб.`);
  });

  $('body').on('submit', '.submit-class', (e) => {
    e.preventDefault();
    const inputVal = $('.submit-class .js_phone').val();
    if (inputVal == '') {
      $('.submit-class .js_phone').parent().addClass('has-error');
      return;
    }
    $('.submit-class .js_phone').parent().removeClass('has-error');
    $('.submit-class .js_phone').parent().addClass('has-success');
    $('.btn-order-fast').addClass('disable-button');
    $('.btn-order-fast').attr('disabled', true);

    $.ajax({
      url: '/site/fast',
      method: 'post',
      data: $('.submit-class').serialize(),
      success(data) {
        $('.popup__fastorder').show();

        $('.btn-order-fast').attr('disabled', false);
        $('.btn-order-fast').remove('disable-button');
      },
    });
  });

  $('.js-order--check-delivery').on('click', function (event) {
    if ($(this).children('.js-order--kind-delivery').prop('checked') == true) {
      $('.js-order--check-delivery')
        .children('.js-order--kind-delivery')
        .prop('checked', false);
      $(this).children('.js-order--kind-delivery').prop('checked', true);
    }
  });

  $('.js-order--kind-delivery').on('change', function (event) {
    if (
      $(this).attr('name') == 'urgently' &&
      $(this).prop('checked') == false
    ) {
      $('.js-cart-index-time-block').addClass('nondisabled');
    }
    if ($(this).attr('name') == 'urgently' && $(this).prop('checked') == true) {
      $('.js-cart-index-time-block').removeClass('nondisabled');
    }

    if ($(this).attr('name') == 'exactly' && $(this).prop('checked') == true) {
      $('.field__time').addClass('d-none');
      $('.field__time--exact').removeClass('d-none');
      $('.field__time--exact').find('input').prop('disabled', false);

      $('.js-cart-index-time-block').addClass('nondisabled');
    }
    if (
      !($(this).attr('name') == 'exactly' && $(this).prop('checked') == false)
    ) {
      return;
    }
    $('.field__time').removeClass('d-none');
    $('.field__time--exact').addClass('d-none');
    $('.field__time--exact').find('input').prop('disabled', true);
    $('.js-cart-index-time-block').addClass('nondisabled');
  });

  $('.js-payment-method-choise').on('click', function (event) {
    if ($(this).val() == 2 || $(this).val() == 3 || $(this).val() == 4) {
      if ($(this).val() == 2) {
        $('.yandex_payment_icons').removeClass('d-none');
        $('.other_payment_icons').addClass('d-none');
      } else {
        $('.yandex_payment_icons').addClass('d-none');
        $('.other_payment_icons').removeClass('d-none');
      }
    } else {
      $('.yandex_payment_icons').addClass('d-none');
      $('.other_payment_icons').addClass('d-none');
    }
    $('#orderform-payment_method').val($(this).val());
  });

  $('.js_radio-delivery').on('change', function (event) {
    if ($(this).attr('data-pickup') == 1) {
      $('.js-cart-index-time-block').addClass('nondisabled');
      $('.js-order--kind-delivery').prop('checked', false);
      if (!$('.field__time--exact').hasClass('d-none')) {
        $('.field__time').removeClass('d-none');
        $('.field__time--exact').addClass('d-none');
      }
    }

    $quantityNum = $('.content-info-cart .js-number-input');
    updateCount($quantityNum);
  });

  const inputTime = $('.js-time-input');
  const inputTimeRange = $('.js-time-range');


  let time_begin = inputTime.data('time_begin');
  let time_end = inputTime.data('time_end');

  function formatDate(date, i = false) {
    let dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;

    let mm = '';
    mm = i ? date.getMonth() + 1 : date.getMonth();

    if (mm < 10) mm = `0${mm}`;

    let yy = date.getFullYear();
    if (yy < 10) yy = `0${yy}`;

    return `${dd}.${mm}.${yy}`;
  }

  $('.datepicker-here').datepicker({
    minDate: today_date,
    dateFormat: "d.m.yy",
    onSelect(dateText, inst) {
      date_select = dateText;

      $('.js-time-input').data('select', date_select);

      const sel_date = $('.js-time-input').data('select');
      const now = formatDate(new Date(), true);

      if (sel_date > now) {
        time_begin = $('.js-time-input').data('time1');
        $('.slider-time').html($('.js-time-input').data('time_slider'));
      } else {
        time_begin = inputTime.data('time_begin');
        time_end = inputTime.data('time_end');
        $('.slider-time').html(inputTime.data('date2'));
      }

      $('.slider-range-time').slider({
        range: true,
        min: time_begin ? time_begin : 0,
        max: time_end ? time_end : 1440,
        step: 15,
        values: [time_begin ? time_begin : 360, time_end ? time_end : 1220],
        slide(e, ui) {
          const step = $('.slider-range-time').data('step');
          if (ui.values[0] + (step - 1) >= ui.values[1]) {
            return false;
          }
          let hours1 = Math.floor(ui.values[0] / 60);
          let minutes1 = ui.values[0] - hours1 * 60;

          if (hours1.length == 1) hours1 = `0${hours1}`;
          if (minutes1.length == 1) minutes1 = `0${minutes1}`;
          if (minutes1 == 0) minutes1 = '00';
          if (hours1 >= 0) {
            hours1 = hours1 == 24 ? hours1 : hours1 - 0;
          } else {
            hours1 = hours1;
          }
          if (hours1 == 0) {
            hours1 = 0;
            minutes1 = minutes1;
          }

          $('.js-time-range .slider-time').html(`${hours1}:${minutes1}`);

          let hours2 = Math.floor(ui.values[1] / 60);
          let minutes2 = ui.values[1] - hours2 * 60;

          if (hours2.length == 1) hours2 = `0${hours2}`;
          if (minutes2.length == 1) minutes2 = `0${minutes2}`;
          if (minutes2 == 0) minutes2 = '00';
          if (hours2 >= 24) {
            if (hours2 == 23) {
              hours2 = hours2;
            } else {
              hours2 = hours2 == 24 ? 23 : hours2 - 24;
            }
            minutes2 = '59';
          } else {
            hours2 = hours2;
          }

          $('.js-time-range .slider-time2').html(`${hours2}:${minutes2}`);
          inputTime.val(`${hours1}:${minutes1} - ${hours2}:${minutes2}`);
          tooltipDatePicker.hide();
        },
      });

      let flag = true;
      // TIME-RANGE
      inputTime.toggleAll({
        notCloseClass: '.time-range__text,time-range__slider',
        uniqueString: 'time-range',
        open($this) {
          $this.attr('disabled', '');
          if (!flag) {
            return;
          }
          flag = false;
          inputTimeRange.css('display', 'block');
          inputTimeRange.addClass('active');
        },
        close($this) {
          flag = true;
          $this.removeAttr('disabled');
          inputTimeRange.css('display', 'none');
          inputTimeRange.removeClass('active');
        },
      });
    },
  });
})();

var tooltipDatePicker = {
  textDelivery: 'Сегодня доставка уже не работает, выберите другой день',
  textDefault: 'Сегодня самовывоз уже не работает, выберите другой день',
  selector: '#tooltip__datepicker',
  element: '<span id="tooltip__datepicker"></span>',
  show(offset) {
    const del_type = $('[name=delively]').prop('checked');
    const text = del_type ? this.textDefault : this.textDelivery;
    $('body').append(this.element);
    $(this.selector)
      .css({
        color: 'red',
        zIndex: 100_000,
        fontSize: '10px',
        textAlign: 'center',
        width: '173px',
        position: 'absolute',
        padding: '5px 0px',
        border: '1px solid #D8D8D8',
        boxShadow: '2px 5px 8px #D8D8D8',
        backgroundColor: '#FFFFFF',
        opacity: '1',
      })
      .text(text);
    $(this.selector).offset({
      top: offset.top - 25,
      left: offset.left - 50,
    });
    $(this.selector).append(
      '<span class="close-tooltip" onclick="$(this).closest(\'#tooltip__datepicker\').remove();"></span>'
    );
  },
  hide() {
    $(this.selector).remove();
  },
};

$('.js-order-chk_autoorder').on('click', function (event) {
  if ($(this).prop('checked') == true) {
    $('.autoorderInterval').css('display', 'block');
    $('.js-order-unchk_autoorder').prop('checked', false);
  }
});

$('.js-order-unchk_autoorder').on('click', function (event) {
  if ($(this).prop('checked') == true) {
    $('.autoorderInterval').css('display', 'none');
    $('.js-order-chk_autoorder').prop('checked', false);
  }
});
