const allReviews = document.querySelectorAll('.reviews__block');
const loadMoreButton = document.querySelector('.reviews__load-more');

if (loadMoreButton) {
  actionReviews(loadMoreButton.dataset.isLoaded == 'false' ? 'hide' : 'show');

loadMoreButton.addEventListener('click', () => {
  const isLoaded = loadMoreButton.dataset.isLoaded;
  actionReviews(isLoaded == 'false' ? 'show' : 'hide');
});

function actionReviews(actionType) {
  allReviews.forEach((review, i) => {
    if (i > 2) {
      switch (actionType) {
        case 'hide':
          review.classList.add('hide');
          break;
        case 'show':
          review.classList.remove('hide');
          break;
      }
    }
  });
  loadMoreButton.textContent =
    actionType == 'show' ? 'Скрыть отзывы' : 'Все отзывы';
  loadMoreButton.dataset.isLoaded = actionType == 'show' ? 'true' : 'false';
}
}

