$('body').on('change', '#editphoneform-phone', function (event) {
  event.preventDefault();
  if ($(this).val() != '' && $(this).val() != $(this).data('phone')) {
    $('.code-btn').removeClass('display_none');
    //  $('.code-wrap').removeClass('display_none');
  } else {
    $('.code-btn').addClass('display_none');
    $('.code-wrap').addClass('display_none');
  }
});

$('body').on('click', '.btn-sm-code', (event) => {
  event.preventDefault();
  const yiiform = $('#changePhone');
  $.ajax({
    url: '/user/profile/code',
    data: { phone: $('#editphoneform-phone').val() },
    type: 'GET',
    success(res) {
      if (res == 'OK') {
        $('.code-wrap').removeClass('display_none');
        yiiform.yiiActiveForm('updateAttribute', 'editphoneform-phone', '');
        alert('Проверочный код отправлен');
        return;
      }
      $.each(res, (key, val) => {
        yiiform.yiiActiveForm('updateAttribute', key, [val]);
      });
    },
    error(err) {
      console.log(err);
    },
  });
});
