$('.has-child').click(function () {
  event.preventDefault(0);
  const el = $(this).closest('.has-submenu');
  el.find('.submenu').toggle(500);
  el.find('.arrow-img').toggleClass('flip');
});
$(document).ready(() => {
  /** Поиск  **/
  let submit = false;
  $('body').on('submit', '.head-form', function (e) {
    if (submit) {
      return;
    }
    e.preventDefault();
    if ($('.head-form__input', $(this)).val().length < 3) {
      alert('Длина поискового запроса должна быть не менее трех символов');
      return false;
    }
    if ($('.head-form__input', $(this)).val().length > 200) {
      alert('Длина поискового запроса должна быть не более 200 символов');
      return false;
    }
    submit = true;
    $(this).submit();
    submit = false;
  });
});

/** Реомендация на основной странице - добавление в инпут**/
$('.recommendation-slider').on('afterChange', () => {
  $('.productRec').val($('.recommendation-slider .slick-current').data('id'));
});

$('.productRec').val($('.recommendation-slider .slick-current').data('id'));

/** Обязательный выбор обработку персональных данных**/
$('body').on('click', '.order__btn', function (e) {
  e.preventDefault();
  const checkPersonalData = $(this)
    .closest('.js-order__box')
    .find('.checkbox__checkbox');
  if (checkPersonalData.is(':checked')) {
    $(this).closest('.js-order__box').find('.has-error1').hide();
    $(this).closest('.js-order__box').find('.has-error2').hide();
    $(this).submit();
    return;
  }
  $(this).closest('.js-order__box').find('.has-error1').show();
  $(this).closest('.js-order__box').find('.has-error2').hide();
});

/**  fast order из плашки корзины **/
$('body').on('click', '.btn-order-fast', function (e) {
  e.preventDefault();
  const checkPersonalData = $(this)
    .closest('.basket-order__footer')
    .find('.checkbox__checkbox');
  if (checkPersonalData.is(':checked')) {
    $(this).closest('.basket-order__footer').find('.has-error1').hide();
    $('.submit-class').submit();
  } else {
    $(this).closest('.basket-order__footer').find('.has-error1').show();
  }
});

/** Сортировка товаров **/
$('body').on('change', '.js-filter-select-catalog', function (event) {
  const link = $(this).data('href');
  const sort = $(this).val();
  $.ajax({
    url: `${link}&${sort}`,
    success(data) {
      data = $.parseJSON(data);
      $('._catalog').html(data.render);
    },
  });
  window.history.pushState({ href: '/category' }, '', `${link}&${sort}`);
});

const urlParams = new URLSearchParams(window.location.search);
if (urlParams.get('sort')) {
  $('.js-filter-select-catalog')
    .val(`sort=${urlParams.get('sort')}`)
    .change();
}

/** Доставка и бонусы в корзине **/
const jsRadioDelivery = $('.js_radio-delivery');
const pickup = $('.js-order-submit');
jsRadioDelivery.click(function () {
  if ($(this).is(':checked') && Number($(this).attr('data-pickup')) === 1) {
    checkFormDelivery({
      input: this,
      pickup,
    });
    return;
  }
  $('.js-urgently-delivery-wrap').show();
  $('#orderform-delivery').val(1);
  $('.not-delivery-price').hide();
  $('.delivery-price').show();
  $('.delivery-block').show();
  pickup.removeClass('active-hidden');
  pickup
    .find('.js-all-address-shop')
    .closest('.field')
    .removeClass('d-block')
    .addClass('d-none');
  pickup
    .find('.js-cart-index-time-block input[name="OrderForm[delivery_date]"]')
    .attr('placeholder', 'Дата доставки');
  pickup
    .find('.js-cart-index-time-block input[name="OrderForm[delivery_time]"]')
    .attr('placeholder', 'Время доставки');
});

$(window).on('load', (event) => {
  event.preventDefault();
  jsRadioDelivery.each((index, el) => {
    checkFormDelivery({
      input: el,
      pickup,
    });
  });
});
function checkFormDelivery($elements) {
  if (
    !(
      $($elements.input).is(':checked') &&
      Number($($elements.input).attr('data-pickup')) === 1
    )
  ) {
    return;
  }
  $('.js-urgently-delivery-wrap').hide();
  $('#orderform-delivery').val(0);
  $('.not-delivery-price').show();
  $('.delivery-price').hide();
  $('.delivery-block').hide();
  $elements.pickup.addClass('active-hidden');
  $elements.pickup
    .find('.js-all-address-shop')
    .closest('.field')
    .removeClass('d-none')
    .addClass('d-block');
  $elements.pickup
    .find('.js-cart-index-time-block input[name="OrderForm[delivery_date]"]')
    .attr('placeholder', 'Дата');
  $elements.pickup
    .find('.js-cart-index-time-block input[name="OrderForm[delivery_time]"]')
    .attr('placeholder', 'Время');
}
/** Переделать выше **/

$('body').on('click', '#bonus', function () {
  if ($(this).is(':checked')) {
    $('#orderform-pay_bonus').val(1);
    $('.no-bonus').hide();
    $('.bonus').show();
    return;
  }
  $('#orderform-pay_bonus').val(0);
  $('.bonus').hide();
  $('.no-bonus').show();
});

// Показать форму промокода
$('.js_show_promo_form').on('click', function (e) {
  e.preventDefault();
  $(this).addClass('d-none');
  $('.form_promo_code').removeClass('display_none');
});
$('.js_promo_button').on('click', (e) => {
  $('.form_promo_code').submit();
});
$('.form_promo_code').on('beforeSubmit', function () {
  let data = $(this).serialize();
  const yiiform = $(this);
  const is_delivery_active = $('.delivery__price').has('strike').length ? 1 : 0;
  $.ajax({
    url: '/cart/promo',
    type: 'POST',
    data,
    success(res) {
      $.each(res, (key, val) => {
        if (key == 'ok') {
          const delivery = $('#orderform-delivery').val();
          const region_id = $('#orderform-region_id').val();
          const urgentlyDelivery = $('#orderform-delivery_options').val();

          $.ajax({
            url: '/cart/update-price',
            type: 'GET',
            data: {
              delivery,
              region_id,
              urgentlyDelivery,
              is_del_active: is_delivery_active,
            },
            success(res) {
              data = $.parseJSON(res);
              $('.js_success_promo').removeClass('d-none');
              $('.form_promo_code').addClass('display_none');
              $('.total__price').html(data);
              $('.js-c-basket--promo').addClass('c-basket--promo');
              $('.js-c-basket-mobile--promo').addClass('c-basket--promo');
            },
            error(err) {},
          });
          return;
        }
        yiiform.yiiActiveForm('updateAttribute', key, [val]);
      });
    },
    error() {
      alert('Error!');
    },
  });
  return false;
});

$('.js_remove_promo').on('click', (e) => {
  e.preventDefault();
  const is_delivery_active = $('.delivery__price').has('strike').length ? 1 : 0;
  $.ajax({
    url: '/cart/delpromo',
    type: 'GET',
    success(res) {
      const delivery = $('#orderform-delivery').val();
      const region_id = $('#orderform-region_id').val();
      const urgentlyDelivery = $('#orderform-delivery_options').val();

      $.ajax({
        url: '/cart/update-price',
        type: 'GET',
        data: {
          delivery,
          region_id,
          urgentlyDelivery,
          is_del_active: is_delivery_active,
        },
        success(res) {
          data = $.parseJSON(res);
          $('.js_success_promo').addClass('d-none');
          $('.input_promo_code').val('');
          $('.js_show_promo_form').removeClass('d-none');
          $('.total__price').html(data);
          $('.js-c-basket--promo').removeClass('c-basket--promo');
        },
        error(err) {},
      });
    },
    error() {
      alert('Error!');
    },
  });
});

$(document).ready(() => {
  const button = $('#btnUp');
  button.on('click', (e) => {
    $('body, html').animate(
      {
        scrollTop: 0,
      },
      800
    );
    return false;
  });

  const whatsAppLink = $('.menu-info__social--bg-whatsapp')
    .attr('href')
    .toString();
  const viberLink =
    $('.menu-info__social--bg-viber').length > 0
      ? $('.menu-info__social--bg-viber').attr('href').toString()
      : '';
  if (whatsAppLink === '') {
    $('#socialBtn').attr('href', viberLink);
  } else {
    $('#socialBtn').attr('href', whatsAppLink);
  }
});
$('#pod_zakaz').on('click', function () {
  $('#productId').val($(this).data('id'));
});
