/**
 * bacs21of@mail.ru
 */
(() => {
  const $ = jQuery;

  let checkingTimer;
  const params = {};

  /** ф-ция обновления каталога с новыми товарами */
  function updateProducts(data) {
    const urlParams = new URLSearchParams(window.location.search);
    let link_ajax = '?';

    if (urlParams.get('sort')) {
      link_ajax = `${link_ajax}sort=${urlParams.get('sort')}`;
    }
    $.ajax({
      url: location.pathname + link_ajax,
      data,
      success(data) {
        data = $.parseJSON(data);
        $('._catalog').html(data.render);

        $('.list-ajax input[name^="size"]').each(function (index) {
          if ($(this).data('id') in data.filter.sizeFilter) {
            $(this)
              .closest('.checkbox_effect')
              .find('.checkbox__square')
              .removeClass('checkbox__nesting_disabled');
            $(this).attr('disabled', false);
          } else {
            $(this)
              .closest('.checkbox_effect')
              .find('.checkbox__square')
              .addClass('checkbox__nesting_disabled');
            $(this).attr('disabled', true);
          }
        });
        $('.list-ajax input[name^="colors_upak"]').each(function (index) {
          if ($(this).data('id') in data.filter.color_upak) {
            $(this)
              .closest('.checkbox_effect')
              .find('.checkbox__circle')
              .removeClass('checkbox__nesting_disabled');
            $(this).attr('disabled', false);
          } else {
            $(this)
              .closest('.checkbox_effect')
              .find('.checkbox__circle')
              .addClass('checkbox__nesting_disabled');
            $(this).attr('disabled', true);
          }
        });
      },
    });
    $('select.c-select').each(function () {
      $(this).data('href', `${location.pathname}?${$.param(data)}`);
    });
    window.history.pushState(
      { href: '/cat' },
      '',
      `${location.pathname + link_ajax}&${$.param(data)}`
    );
  }

  /** отслеживание изменения выбора цветов */
  $('body').on('change', '.list-categories input:checkbox', () => {
    $('input:checkbox').each(function () {
      if (params[this.name] !== undefined) {
        delete params[this.name];
      }
    });

    $('input:checkbox:checked').each(function () {
      params[this.name] = 1;
    });

    updateProducts(params);
  });

  /** отслеживание изменения фильтра стоимости - min */
  $('.range-min').change(function () {
    if (+params['minPrice'] !== +$(this).val()) {
      params['minPrice'] = $(this).val();
      delayUpdateProducts(params);
    }
  });

  /** отслеживание изменения фильтра стоимости - max */
  $('.range-max').change(function () {
    if (+params.maxPrice !== +$(this).val()) {
      params.maxPrice = $(this).val();
      delayUpdateProducts(params);
    }
  });

  function delayUpdateProducts(params) {
    clearTimeout(checkingTimer);
    checkingTimer = setTimeout(() => {
      updateProducts(params);
    }, 1000);
  }
})();
$(document).ready(() => {
  window.addEventListener('popstate', (e) => {
    if (e.state) window.location.href = location.pathname;
  });
});
