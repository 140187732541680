{
  const $ = jQuery;

  // SIDEBAR MEBU
  $('.js-burger').toggleAll({
    notCloseClass:
      '.head-form, .sidebar-mobile__footer, .select-option, .sidebar-mobile__content',
    uniqueString: 'sidebar',
    open($this) {
      const heightHead = $this.closest('body').find('.head').outerHeight();
      $this.closest('body').addClass('js-body-sidebar-menu');
      $this
        .closest('body')
        .find('.sidebar-mobile')
        .css({
          height: `calc(100% - ${heightHead - 50}px)`,
        });
    },
    close($this) {
      $this.closest('body').removeClass('js-body-sidebar-menu');
    },
  });

  // CATALOG
  $('.js-burger-head-catalog').toggleAll({
    notCloseClass: '.head-catalog__list',
    uniqueString: 'head-catalog',
    open($this) {
      $this.closest('body').addClass('js-body-head-catalog');
    },
    close($this) {
      $this.closest('body').removeClass('js-body-head-catalog');
    },
  });

  // Выпадающие Селекты
  $('.js-click-open-select-option').toggleAll({
    notCloseClass: '.select-option__list',
    uniqueString: 'money-lang',
    open($this) {
      $this.parent().addClass('active');
    },
    close($this) {
      $this.parent().removeClass('active');
    },
  });
  // BASKET
  $('.js_basket-open').toggleAll({
    notCloseClass:
      '.basket-order__total, .basket-order__item, .basket-order__total, .basket-order__footer',
    uniqueString: 'basket-goods',
    open($this) {
      $this.closest('body').addClass('js-open-basket overlay');
    },
    close($this) {
      $this.closest('body').removeClass('js-open-basket overlay');
    },
  });

  // Показать БОЛЬШЕ СТРАНИЦА GOODS.html
  $('.js-show-more-edge').toggleAll({
    // notCloseClass: '.js-edge',
    uniqueString: 'show-more-edge',
    open($this) {
      $this.text($this.attr('data-text'));
      $this.closest('.edge__col_last').addClass('active');
    },
    close($this) {
      $this.removeClass('active');
      $this.text('Подробнее').removeClass('active');
      $this.closest('.edge__col_last').removeClass('active');
    },
  });
}
