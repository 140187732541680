import LazyLoad from 'lazyload';
import hcSticky from 'hc-sticky';

(() => {
  const $ = jQuery;
  const Sticky = new hcSticky('.js_head', {
    stickTo: '.wrapper',
    innerSticker: '.head-bottom',
    responsive: {
      1199: {
        innerSticker: '.head-catalog',
      },
    },
  });

  if ($('.c-billet-promo-code').length) {
    let flag = true;
    $('.c-billet-promo-code').on('click', () => {
      flag = true;
    });
    $(window).on('scroll load', () => {
      const $head = $('.js_head');
      const $cBillet = $head.find('.c-billet-promo-code');
      const height = $cBillet.outerHeight();
      if (!flag) {
        return;
      }
      Sticky.update({
        top: height,
        responsive: {
          1199: {
            innerSticker: '.head-catalog',
          },
        },
      });
      Sticky.refresh();
      flag = false;
    });
  }

  $('#question').hover(() => {
    $('.modal_question').show();
  });

  // ACCORDEON
  $('.js_acordion-btn').click(function (event) {
    $(this).toggleClass('active').next().slideToggle();
  });

  // MOBILE FILTER OPEN
  $('.js_sorting-btn').click(function (event) {
    $(this).toggleClass('active');
    $('.js-sidebar-filter').slideToggle();
  });

  // LOCATION CUSTOM LINK
  if ($('[data-location-href]').length >= 1) {
    $('[data-location-href]').on('click', function () {
      window.location = $(this).attr('data-location-href');
    });
  }

  if ($('.js-select-slim').length >= 1) {
    $('.js-select-slim').each((index, el) => {
      new SlimSelect({
        select: el,
        showSearch: false,
      });
    });
  }
  $('.repeat_ajax').on('click', (e) => {
    e.preventDefault();
    const yiiform = $('#confirm_form');
    $.ajax({
      url: '/user/registration/repeat',
      type: 'GET',
      data: { phone: $('#confirmform-phone').val() },
      success(res) {
        $.each(res, (key, val) => {
          if (val == 'ok') {
            $('.timer_wrap').show();
            $('.h-ta-center').find('.repeat_ajax').hide();
            $('.timer_sms').timer({
              duration: 59, // Секунды
              do: (data) => {
                data.elem.html(data.s);
              },
              success: ($this) => {
                $this.closest('.timer_wrap').hide();
                $this.closest('.h-ta-center').find('.repeat_ajax').show();
              },
            });
            return;
          }
          yiiform.yiiActiveForm('updateAttribute', key, [val]);
        });
      },
      error() {
        alert('Error!');
      },
    });
  });

  $('#register_form').on('beforeSubmit', function () {
    const data = $(this).serialize();
    const yiiform = $(this);
    $.ajax({
      url: '/user/registration/register',
      type: 'POST',
      data,
      success(res) {
        $.each(res, (key, val) => {
          if (key == 'phone') {
            $('.jsx-modal').fadeOut();
            $('.popup_confirm').addClass('jsx-active');
            $('.timer_sms').timer({
              duration: 59, // Секунды
              do: (data) => {
                data.elem.html(data.s);
              },
              success: ($this) => {
                $this.closest('.timer_wrap').hide();
                $this.closest('.h-ta-center').find('.repeat_ajax').show();
              },
            });
            $("[data-jsx-modal-id='popup_authorization']").fadeIn();

            $('#confirmform-phone').val(val);
            $('.success-reg').css('display', 'block');
          }
          yiiform.yiiActiveForm('updateAttribute', key, [val]);
        });
      },
      error() {
        alert('Error!');
      },
    });
    return false;
  });

  $('#recovery_form').on('beforeSubmit', function () {
    const data = $(this).serialize();
    const yiiform = $(this);
    $.ajax({
      url: '/user/security/recovery',
      type: 'POST',
      data,
      success(res) {
        $.each(res, (key, val) => {
          if (key == 'phone') {
            $('.jsx-modal').fadeOut();
            $('.popup_confirm').addClass('jsx-active');
            $("[data-jsx-modal-id='popup_recovery']").fadeIn();
            $('.timer_sms').timer({
              duration: 59, // Секунды
              do: (data) => {
                data.elem.html(data.s);
              },
              success: ($this) => {
                $this.closest('.timer_wrap').hide();
                $this
                  .closest('.h-ta-center')
                  .find('.repeat_ajax_recovery')
                  .show();
              },
            });
            $('#passwordresetform-phone').val(val);
          }
          yiiform.yiiActiveForm('updateAttribute', key, [val]);
        });
      },
      error() {
        alert('Error!');
      },
    });
    return false;
  });

  $('#reset_form').on('beforeSubmit', function () {
    const data = $(this).serialize();
    const yiiform = $(this);
    $.ajax({
      url: '/user/security/reset',
      type: 'POST',
      data,
      success(res) {
        $.each(res, (key, val) => {
          if (key == 'ok') {
            location.href = '/user/profile';
          }
          yiiform.yiiActiveForm('updateAttribute', key, [val]);
        });
      },
      error() {
        alert('Error!');
      },
    });
    return false;
  });

  $('.repeat_ajax_recovery').on('click', (e) => {
    e.preventDefault();
    const yiiform = $('#reset_form');
    $.ajax({
      url: '/user/registration/repeat',
      type: 'GET',
      data: { phone: $('#passwordresetform-phone').val() },
      success(res) {
        $.each(res, (key, val) => {
          if (val == 'ok') {
            $('.timer_wrap').show();
            $('.h-ta-center').find('.repeat_ajax_recovery').hide();
            $('.timer_sms').timer({
              duration: 59, // Секунды
              do: (data) => {
                data.elem.html(data.s);
              },
              success: ($this) => {
                $this.closest('.timer_wrap').hide();
                $this
                  .closest('.h-ta-center')
                  .find('.repeat_ajax_recovery')
                  .show();
              },
            });
            return;
          }
          yiiform.yiiActiveForm('updateAttribute', 'passwordresetform-code', [
            val,
          ]);
        });
      },
      error() {
        alert('Error!');
      },
    });
  });

  $('#confirm_form').on('beforeSubmit', function () {
    const data = $(this).serialize();
    const yiiform = $(this);
    $.ajax({
      url: '/user/registration/confirm',
      type: 'POST',
      data,
      success(res) {
        $.each(res, (key, val) => {
          if (key == 'ok') {
            location.href = '/user/profile';
          } else {
            yiiform.yiiActiveForm('updateAttribute', key, [val]);
          }
        });
      },
      error() {
        alert('Error!');
      },
    });
    return false;
  });

  // LAZY LOAD
  const images = document.querySelectorAll('.h-lazy');
  new LazyLoad(images);

  // smooth scroll

  $('a.js-scrollto').click(function () {
    const elementClick = `#${$(this).attr('href').split('#')[1]}`;
    const destination = $(elementClick).offset().top - 70;
    $('html:not(:animated),body:not(:animated)').animate(
      { scrollTop: destination },
      500
    );
    return false;
  });

  //search call

  $(document).mouseup((e) => {
    const search = $('.js-search-show');
    const searchactive = $('.js-search-show.active');
    const searchbar = $('.sidebar-mobile__head');

    if (search.is(e.target) && !search.hasClass('active')) {
      searchbar.removeClass('hidden').addClass('active');
      search.addClass('active');
    }

    if (
      (!searchbar.is(e.target) &&
        searchbar.has(e.target).length === 0 &&
        !search.is(e.target)) ||
      searchactive.is(e.target)
    ) {
      searchbar.addClass('hidden').removeClass('active');
      search.removeClass('active');
    }
  });

  $(document).ready(() => {
    if ($('#settings55').val() == 1 && getCookie('MultiCity') == undefined) {
      $('.popup__multi-city').show();
      setCookie('MultiCity', '1', '365');
    }
  });

  $('.js-close-billet').on('click', (event) => {
    setCookie('promobar', 'small', 'Date(Date.now() + 86400e3)');
  });
  $('.js-open-billet').on('click', (event) => {
    setCookie('promobar', '', '');
  });

  $(document).ready(() => {
    $('.js-read-more').elimore({
      maxLength: 200,
      moreText: 'Читать дальше',
      lessText: 'Свернуть',
    });
  });
})();

//cookies
function setCookie(name, value, expires, path, domain, secure) {
  document.cookie =
    name +
    '=' +
    escape(value) +
    (expires ? `; expires=${expires}` : '') +
    (path ? `; path=${path}` : '') +
    (domain ? `; domain=${domain}` : '') +
    (secure ? '; secure' : '');
}

function getCookie(name) {
  const cookie = ` ${document.cookie}`;
  const search = ` ${name}=`;
  let offset = 0;
  let end = 0;
  if (cookie.length > 0) {
    offset = cookie.indexOf(search);
    if (offset != -1) {
      offset += search.length;
      end = cookie.indexOf(';', offset);
      if (end == -1) {
        end = cookie.length;
      }
      return unescape(cookie.substring(offset, end));
    }
  }
  return null;
}

$(document).on('click', '.datepicker--cell', (e) => {
  $('.datepicker').removeClass('active');
  $('.recommendation__input').blur();
});

$('.c-catalog-menu__burger').on('click', function () {
  $('.c-catalog-menu__dropdown').fadeToggle();
  $(this).find('.c-nav-icon').toggleClass('open');
  $('.c-catalog-menu__shadow').fadeToggle();
});

$('.c-catalog-menu__shadow').on('click', function () {
  $('.c-catalog-menu__dropdown').fadeOut();
  $('.head-bottom').find('.c-nav-icon').toggleClass('open');
  $(this).fadeToggle();
});

const length = $('.c-catalog-menu__col').length;

if (length < 4) {
  $('.c-catalog-menu__wrap').css({ 'column-count': length });
} else if (length >= 4) {
  $('.c-catalog-menu__wrap').css({ 'column-count': '4' });
}

$('.popup__close').on('click', function () {
  $(this).parents('.jsx-modal').fadeOut();
});

$(document).mouseup((e) => {
  const div = $('.js-popup-close').find('.popup__block');
  if (!div.is(e.target) && div.has(e.target).length === 0) {
    div.parents('.js-popup-close').fadeOut();
  }
});

$(document).ready(() => {
  $('.js-stock-search:contains("Акции")').addClass('stock');

  //Filter accordion
  $('.list-categories__name').click(function (e) {
    e.preventDefault();
    const t = $(this);
    const ch = $(this).closest('li').find('ul.list-categories__drop-list');
    if (t.hasClass('active')) {
      ch.hide();
      t.removeClass('active');
    } else {
      t.addClass('active');
      ch.show();
    }
  });

  const regularElement = $('.regular');
  if (regularElement.length > 0) {
    regularElement.slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 1000,
    });
  }
});
function getOrderDetails(el) {
  const id = $(el).data('id');
  $.ajax({
    url: '/user/profile/order-details',
    type: 'POST',
    data: { orderNumber: id },
    success(res) {
      const result = JSON.parse(res);
      $('#tab-3').html(result.details);
      $('#tab-3').css('display', 'block');
      $('#tab-1').css('display', 'none');
      $('#tab-2').css('display', 'none');
    },
    error() {
      alert('Error!');
    },
  });
}

function getAutoOrderDetails(el) {
  const id = $(el).data('id');
  $.ajax({
    url: '/user/profile/auto-order-details',
    type: 'POST',
    data: { orderNumber: id },
    success(res) {
      const result = JSON.parse(res);
      $('#autoorder').html(result.details);
      $('#autoorder').css('display', 'block');
      $('#tab-1').css('display', 'none');
      $('#tab-2').css('display', 'none');
    },
    error() {
      alert('Error!');
    },
  });
}

$('#gost').on('click', (event) => {
  event.preventDefault();
});

function saveAutoOrder(el) {
  const id = $(el).data('id');
  const quantity = $(`#amount${id}`).val();
  $.ajax({
    url: '/user/profile/save-auto-order',
    type: 'POST',
    data: { quantity, id },
    success(res) {
      const result = JSON.parse(res);
      alert(result.message);
    },
    error() {
      alert('Error!');
    },
  });
}

function deleteAutoOrder(el) {
  const id = $(el).data('id');
  $.ajax({
    url: '/user/profile/delete-auto-order',
    type: 'POST',
    data: { id },
    success(res) {
      $(`#raw${id}`).remove();
    },
    error() {
      alert('Error!');
    },
  });
}
