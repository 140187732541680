(() => {
  const $ = jQuery;
  if ($('.js-range').length < 1) {
    return;
  }
  const jsMinVal = $('.range-value-1');
  const jsMaxVal = $('.range-value-2');

  const jsItem = $('.js-ui-slide-change').find('div');

  const jsMinValInput = $('.range-min');
  const jsMaxValInput = $('.range-max');

  function changes($one, $two) {
    jsMinVal.text($one);
    jsMaxVal.text($two);

    jsMinValInput.val($one);
    jsMinValInput.trigger('change');
    jsMaxValInput.val($two);
    jsMaxValInput.trigger('change');
  }

  jsItem.on('click', function (event) {
    const $this = $(this);

    jsItem.removeClass('active');
    $this.addClass('active');

    const valsClick = $this.attr('data-value').split(',');
    event.preventDefault();
    changes(valsClick[0], valsClick[1]);
  });
})();
