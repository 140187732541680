/**
 * bacs21of@mail.ru
 */
(() => {
  const $ = jQuery;
  let checkingTimer;
  const params = {};

  /** ф-ция обновления каталога с новыми товарами */
  function updateProducts(data) {
    const urlParams = new URLSearchParams(window.location.search);
    let link_ajax = urlParams.get('id') ? `id=${urlParams.get('id')}` : '';

    if (urlParams.get('sort')) {
      link_ajax = `${link_ajax}&sort=${urlParams.get('sort')}`;
    }
    $.ajax({
      url: `${window.location.pathname}?${link_ajax}`,
      data,
      success(data) {
        data = $.parseJSON(data);
        $('._catalog').html(data.render);
      },
    });
    window.history.pushState(
      { href: '/category' },
      '',
      `${window.location.pathname}?${link_ajax}&${$.param(data)}`
    );
  }

  /** отслеживание изменения выбора цветов */
  $('body').on('change', '.list-categories input:checkbox', () => {
    $('input:checkbox').each(function () {
      if (params[this.name] !== undefined) {
        delete params[this.name];
      }
    });

    $('input:checkbox:checked').each(function () {
      params[this.name] = 1;
    });
    location.href = `/catalog/cat?${$.param(params)}`;
    // updateProducts(params);
  });

  /** отслеживание изменения фильтра стоимости - min */
  $('.range-min').change(function () {
    if (+params['minPrice'] !== +$(this).val()) {
      params['minPrice'] = $(this).val();
      delayUpdateProducts(params);
    }
  });

  /** отслеживание изменения фильтра стоимости - max */
  $('.range-max').change(function () {
    if (+params.maxPrice !== +$(this).val()) {
      params.maxPrice = $(this).val();
      delayUpdateProducts(params);
    }
  });

  function delayUpdateProducts(params) {
    clearTimeout(checkingTimer);
    checkingTimer = setTimeout(() => {
      updateProducts(params);
    }, 1000);
  }
})();
$(document).ready(() => {
  window.addEventListener('popstate', (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (e.state)
      window.location.href = `/catalog/category?id=${urlParams.get('id')}`;
  });
});
